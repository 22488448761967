import React from "react";
import Ustav from "../assets/ustav-ingis.pdf";
// import CardSvg from "../assets/img/cart.svg";

const Documents = () => {
  return (
    <>
      <div className="content">
        <div className="documents">
          <div className="documents--title-one">Документы</div>
          <div className="documents--title">
            <div class="doc_info">
              <div class="doc_name">
                <a href={Ustav}>
                  <h3>
                    Устав благотворительного фонда поддержки исторической памяти
                    ингушского народа "ИНГИС"
                  </h3>
                </a>
              </div>

              <div class="doc_open">
                <a href={Ustav}>
                  <span>открыть</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Documents;
