import React from "react";

// import CardSvg from "../assets/img/cart.svg";

const Contacts = () => {
  return (
    <>
      <div className="content">
        <div className="footer__contacts footer__column">
          <div className="footer__contacts--title footer--title">Контакты</div>
          <div className="footer__contacts--phone">
            <p>Учредитель БФ "ИНГИС": тел. 8(985) 970-74-30</p>
            <p>Директор БФ "ИНГИС": тел. 8(928) 090-51-52</p>
            <p>Делопроизводитель: тел. 8(938) 012-03-07</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacts;
