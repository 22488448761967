import React from "react";
// import LogoSvg from "../assets/img/logoingis.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
      <div className="container">
        <ul className="header__navbar-nav">
          <li className="header__requisites dropdown">
            <Link to="/cart.html" className="button button--project link">
              <span className="navbar_span">Поддержать проект</span>
            </Link>
          </li>
          <li className="header__home active">
            <Link to="/" className="link">
              <span className="navbar_span">Главная</span>
            </Link>
          </li>
          <li className="header__about-fond dropdown">
            <Link to="/about.html" className="link">
              <span className="navbar_span">О фонде</span>
            </Link>
          </li>
          <li className="header__documents dropdown">
            <Link to="/documents.html" className="link">
              <span className="navbar_span">Документы</span>
            </Link>
          </li>
          <li className="header__contacts dropdown">
            <Link to="/contacts.html" className="link">
              <span className="navbar_span">Контакты</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
