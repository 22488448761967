import React from "react";
import { useEffect } from "react";

import Ustav from "../assets/ustav-ingis.pdf";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="content" id="#top">
        <div className="documents">
          <div className="documents--title">
            <div class="doc_info">
              <div class="doc_name">
                <a href={Ustav}>
                  <h3>
                    Устав благотворительного фонда поддержки исторической памяти
                    ингушского народа "ИНГИС"
                  </h3>
                </a>
              </div>

              <div class="doc_open">
                <a href={Ustav}>
                  <span>открыть</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="structure--fond">
          <div className="structure--fond">
            <div className="structure--fond--rows">
              <table className="structure__table--one">
                <tr>
                  <td colspan="3" className="structure--fond--title">
                    Состав благотворительного фонда
                  </td>
                </tr>
                <tr>
                  <td colspan="3">Совет Фонда</td>
                </tr>
                <tr>
                  <td className="pad">№п.п</td>
                  <td>Ф.И.О</td>
                  <td>Должности</td>
                </tr>
                <tr>
                  <td className="pad">1.</td>
                  <td>Картоев Магомед Муссаевич</td>
                  <td>
                    Руководитель Государственной архивной службы Республики
                    Ингушетия
                  </td>
                </tr>
                <tr>
                  <td className="pad">2.</td>
                  <td>Сагов Магомед Закриевич</td>
                  <td>
                    Руководитель ГКУ "Ингушской государственный музей
                    краеведения им. Т. Мальсагова"
                  </td>
                </tr>

                <tr>
                  <td className="pad">3.</td>
                  <td>Мартазанов Мусса Яхьяевич</td>
                  <td>Председатель Союза художников Республики Ингушетия</td>
                </tr>

                <tr>
                  <td className="pad">4.</td>
                  <td>Харсиев Борис Магомед-Гиреевич</td>
                  <td>
                    Директор ГБУ "ингушский научно-исследовательский институт
                    гуманитарных наук им. ч.э. Ахриева"
                  </td>
                </tr>

                <tr>
                  <td className="pad">5.</td>
                  <td>Газиков Берснако Джабраилович</td>
                  <td>Ингушский исследователь-краевед</td>
                </tr>

                <tr>
                  <td className="pad">6.</td>
                  <td>Цороев Ахмед Хаджибикарович</td>
                  <td>Депутат Народного Собрания Республики Ингушетия</td>
                </tr>
              </table>
              <table>
                <tr>
                  <td colspan="3" className="structure--fond--title">
                    Попечительский Совет
                  </td>
                </tr>
                <tr>
                  <td className="pad">№п.п</td>
                  <td>Ф.И.О</td>
                  <td>Должности</td>
                </tr>
                <tr>
                  <td className="pad">1.</td>
                  <td>Гойгова Хадижат Магомедовна</td>
                  <td>
                    Заместитель главы администрации МО "Городской округ город
                    Магас"
                  </td>
                </tr>
                <tr>
                  <td className="pad">2.</td>
                  <td>Султыгов Али Хаджибикарович</td>
                  <td>Начальник Штаба "Российского Союза ветеранов" по РИ</td>
                </tr>

                <tr>
                  <td className="pad">3.</td>
                  <td>Местоев Осман Муссаевич</td>
                  <td>
                    Главный специалист Управления внутренней политики
                    Администрации Главы и Правительства РИ
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div className="page__about-fond about-fond--row">
          <div className="fond--tasks about-fond--column">
            <div className="fond--tasks--title">
              Фонд создается в целях решения следующих задач:
            </div>
            <ul>
              <li>
                &mdash; На Аллее «100-летия Ингушетии», в г.Магасе, заложить и
                оборудовать информационными материалам «Сквер исторической
                памяти ингушского народа»;
              </li>
              <li>
                &mdash; Содействия в поиске материалов об историческом прошлом
                ингушского народа;
              </li>
              <li>
                &mdash; Для размещения всех материалов в едином центре и их
                демонстрации построить здание «Галереи памяти»
              </li>
              <li>
                &mdash; Установить памятник первому руководителю Ингушетии
                Идрису Бейсултановичу Зазикову.
              </li>
              <li>
                &mdash; Фонд ежегодно публикует отчёты о своей деятельности.
              </li>
            </ul>
          </div>
          <div className="fond--purpose about-fond--column">
            <div className="fond--tasks--title">
              Цели и предмет деятельности фонда
            </div>
            <p>
              &mdash; Основной целью Фонда является привлечение финансовых
              средств и формирование имущества от физических и юридических лиц
              на основе добровольных взносов и пожертвований, а также иных не
              запрещенных законом поступлений, с целью использования этих
              средств и этого имущества на деятельность по сооружению памятника
              первому руководителю Ингушетии Зязикову И.Б., сбору материалов об
              историческом прошлом ингушского народа и строительству
              историко-культурного - сооружения на Аллее «100-летия Ингушетии» в
              городе Магас, а также благоустройство данной территории.
            </p>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="list-of-donors">
          <div className="list-of-donors--title">Список жертвователей</div>
          <div className="list-of-donors-row">
            <div className="list-of-donors__individuals list-of-donors-column">
              <div className="list-of-donors__individuals--title">
                Пожертвования от частных лиц:
              </div>
              <p>
                Калиматов Махмуд Али Макшарипович — Глава Республики Ингушетия;
              </p>
              <p>
                Исса Магометович Костоев - советник Главы Республики Ингушетия;
              </p>
              <p>
                Паланкоев Ахмет Магомедович - предприниматель, президент
                компании "Группа Акрополь".
              </p>
              <p>Костоев Магомед Джабраилович</p>
              <p title="Этот текст будет показан при наведении">
                Точиева Светлана Яхьяевна{" "}
                <abbr title="Возврат внесенных в качестве добровольного пожертвования денежных средств">
                  (возврат)
                </abbr>
              </p>
            </div>
            <div className="list-of-donors__legal-entities list-of-donors-column">
              <div className="list-of-donors__legal-entities--title">
                Пожертвования от юридических лиц:
              </div>
              <p>
                Хутиев Рамазан Магомедович (Генеральный Директор) - ООО
                "КОНСАЛТ"
              </p>
              <p>ООО "Мосавтодор"</p>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="documents">
          <div className="documents--title-one">Документы</div>
          <div className="documents--title">
            <div class="doc_info">
              <div class="doc_name">
                <a href={Ustav}>
                  <h3>
                    Устав благотворительного фонда поддержки исторической памяти
                    ингушского народа "ИНГИС"
                  </h3>
                </a>
              </div>

              <div class="doc_open">
                <a href={Ustav}>
                  <span>открыть</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
