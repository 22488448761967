import React from "react";

import NotFoundBlock from "../components/NotFoundBlock";

const NotFound = () => {
  return (
    <>
      <NotFoundBlock />
    </>
  );
};
export default NotFound;
