import React from "react";
import MainImgBg from "../assets/img/bg_main3.jpg";
import AboutMan from "../assets/img/aboutman.png";
import Issa from "../assets/img/issa.jpg";
import Video from "../assets/img/ingis.mp4";

import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div className="main__top">
        <div className="main__bg">
          <img src={MainImgBg} alt="background" width="100%" />
          <div className="main__text">
            <div className="main__title">
              Благотворительный фонд поддержки исторической памяти ингушского
              народа "ИНГИС"
            </div>
            <div className="main__desc">
              Народ, не помнящий своего прошлого, не имеет будущего. Историю без
              народа всегда захочет присвоить народ без истории. <br></br>
              <span>В.О. Ключевский</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main__about-fond">
        <div className="main__about_left">
          <div className="about_left__title">О фонде</div>
          <div className="about_left__desc">
            Цель создания фонда - сбор и демонстрация в специальной галерее всех
            сведений об истории ингушского народа.
          </div>
          <div className="about_left__button">
            <Link to="/about.html" className="about_left__button_link">
              Подробнее...
            </Link>
          </div>
        </div>

        <div className="main__about_right">
          <img src={AboutMan} alt="man" width="100%" />
          <div className="main__man_foto_desc">
            Эскиз будущего памятника выдающемуся общественно-политическому и
            государственному деятелю, основателю ингушской государственности
            Идрису Бейсултановичу Зязикову
          </div>
        </div>
      </div>

      <hr></hr>

      <div className="main__founder">
        <div className="founder-title founder-column">
          <h4>УЧРЕДИТЕЛЬ ФОНДА</h4>
          <h3>
            Исса Магометович Костоев - Советник Главы Республики Ингушетия.
          </h3>
          <p>
            «Если государственного финансирования не будет, то желательно
            объявить общенародный сбор средств. Такое решение, в условиях
            фамильно-тейповой разделенности, избавит нас в будущем от
            всевозможных суждений об истории создания этих памятников.
          </p>
        </div>
        <div className="founded__img founder-column">
          <img src={Issa} alt="issa" width="400px" />
        </div>
      </div>

      <div className="main__block-video">
        <div className="main__block-video__title">
          <h2>Видео материалы</h2>
        </div>
        <div className="main__block-video__video">
          <video width="90%" height="500" controls>
            <source src={Video} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  );
};

export default Home;
