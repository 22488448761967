import React from "react";

const Card = () => {
  return (
    <>
      <div class="content">
        <div className="footer_recs footer__column">
          <div className="footer_recs--title footer--title">Реквизиты:</div>
          <div className="footer_recs--pad">
            <p>
              Получатель: Благотворительный фонд поддержки исторической памяти
              ингушского народа "ИНГИС"
            </p>
            <p>Банк получателя: СТАВРОПОЛЬСКОЕ ОТДЕЛЕНИЕ №5230 ПАО СБЕРБАНК</p>
            <p>БИК: 040702615</p>
            <p>Сч.№ Банка получателя: 30101810907020000615</p>
            <p>Сч.№ получателя: 40703810260350000463</p>
            <p>"ИНН/КПП": 0600016355/060001001</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
