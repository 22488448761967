import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__row">
          <div className="footer__contacts footer__column">
            <div className="footer__contacts--title footer--title">
              Контакты
            </div>
            <div className="footer__contacts--phone">
              <p>Учредитель БФ "ИНГИС": тел. 8(985) 970-74-30</p>
              <p>Директор БФ "ИНГИС": тел. 8(928) 090-51-52</p>
              <p>Делопроизводитель: тел. 8(938) 012-03-07</p>
            </div>
          </div>

          <div className="footer_recs footer__column">
            <div className="footer_recs--title footer--title">Реквизиты</div>
            <div className="footer_recs--pad">
              <p>
                Получатель: Благотворительный фонд поддержки исторической памяти
                ингушского народа "ИНГИС"
              </p>
              <p>
                Банк получателя: СТАВРОПОЛЬСКОЕ ОТДЕЛЕНИЕ №5230 ПАО СБЕРБАНК
              </p>
              <p>БИК: 040702615</p>
              <p>Сч.№ Банка получателя: 30101810907020000615</p>
              <p>Сч.№ получателя: 40703810260350000463</p>
              <p>"ИНН/КПП": 0600016355/060001001</p>
            </div>
          </div>
        </div>
      </div>
      <div className="developer">
        <a
          href="
https://t.me/Aushev_Imran_1270"
          target="_blank"
        >
          Разработчик Аушев И
        </a>
      </div>
    </div>
  );
};

export default Footer;
