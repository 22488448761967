import React from "react";
import { Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Homes";
import About from "./pages/About";
import Documents from "./pages/Documents";
import Contacts from "./pages/Contacts";
import Card from "./pages/Card";
import NotFound from "./pages/NotFound";

import "./scss/app.scss";

function App() {
  return (
    <div className="wrapper">
      <Header />
      <div className="main">
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="About.html" element={<About />} />
            <Route path="Documents.html" element={<Documents />} />
            <Route path="Contacts.html" element={<Contacts />} />
            <Route path="cart.html" element={<Card />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
